import React from 'react'
import Layout from '../../components/Layout'
import Head from '../../components/Head'
import SEOContactPage from '../../components/SEO/contactPage'
import MaskedInput from 'react-text-mask'
import MapLocation from '../../components/mapLocation'

import { useStaticQuery, graphql } from "gatsby"

export default function Contact() {

  const pageData = useStaticQuery(graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    graphCmsContactPage {
      title
      slug
      id
      metaTitle
      metaDescription
      topContent {
        html
      }
      rightSideContent {
        html
      }
    }
  }
  `)

  const pageUrl = pageData.site.siteMetadata.siteUrl + "/" + pageData.graphCmsContactPage.slug + "/";

  return (
    <Layout>
      <Head
        title={pageData.graphCmsContactPage.metaTitle}
        pageDesc={pageData.graphCmsContactPage.metaDescription}
        url={pageUrl}
      />
      <SEOContactPage
        title={pageData.graphCmsContactPage.metaTitle}
        meta_description={pageData.graphCmsContactPage.metaDescription}
        url={pageUrl}
      />
      <div className="pageSection">
        <h1>{pageData.graphCmsContactPage.title}</h1>
        <div className="center-text" dangerouslySetInnerHTML={{ __html: pageData.graphCmsContactPage.topContent.html }} ></div>
        <hr />
        <div className="boxHalfRow">
        <div className="boxHalf">
          <form autoComplete="on" action="https://formsubmit.co/f1516c1c8a1dacc4e86bfb7f3292946f" method="POST">
            <fieldset>
              <label htmlFor="BusinessName">Business Name</label>
              <input placeholder="Business Name" htmlFor="organization" id="BusinessName" type="text" name="BusinessName" required />
            </fieldset>
            <fieldset>
              <label htmlFor="FirstName">First Name</label>
              <input placeholder="First Name" htmlFor="given-name" id="FirstName" type="text" name="FirstName" required />
            </fieldset>
            <fieldset>
              <label htmlFor="LastName">Last Name</label>
              <input placeholder="Last Name" htmlFor="family-name" id="LastName" type="text" name="LastName" required />
            </fieldset>
            <fieldset>
              <label htmlFor="PhoneNumber">Phone Number</label>
              <MaskedInput
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                className="form-control"
                placeholder="Phone Number"
                guide={true}
                name="PhoneNumber"
                type="tel"
                id="PhoneNumber"
                required
              />
            </fieldset>
            <fieldset>
              <label htmlFor="email">Email Address</label>
              <input placeholder="Email" id="email" type="email" name="email" required />
            </fieldset>
            <fieldset>
              <label htmlFor="message">Description of your issue</label>
              <textarea id="message" name="message" placeholder="Description of your issue" required />
            </fieldset>
            <input type="hidden" name="_next" value="https://www.valleycommercialservice.com/contact-success/" />
            <input type="hidden" name="_template" value="table" />
            <input type="hidden" name="_subject" value="New: Website Contact Form Submission" />
            <fieldset>
              <button className="main-button" type="submit">Send</button>
            </fieldset>
          </form>
        </div>
        <div className="boxHalf">
          <div dangerouslySetInnerHTML={{ __html: pageData.graphCmsContactPage.rightSideContent.html }} ></div>
          <MapLocation />
        </div>
        </div>
      </div>
    </Layout>
  )
}
